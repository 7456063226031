export class WorkflowStatesConstant {
  static readonly ROOT = { STATE: "root" };
  static readonly ENTRY = { STATE: "entry" };
  static readonly WORKFLOW = { STATE: "workflow" };
  static readonly WORKFLOW_ACTION = { STATE: "workflow-action" };
  static readonly APPLICANT_ACTION = { STATE: "applicant-action" };
  static readonly START_PERSON = { STATE: "start-person" };
  static readonly START_BUSINESS = { STATE: "start-business" };
  static readonly START_CUSTOM = { STATE: "w" }
  static readonly BANNO_SSO_ORIGIN = { STATE: "banno-sso-origin" };
  static readonly REDIRECT = { STATE: "redirect" };
  static readonly BUSINESS_CONTINUE = { STATE: "business-continue" };
  static readonly CONTINUE = { STATE: "continue" };
  static readonly VALIDATE_TOKEN = { STATE: "validate-token" };
  static readonly ERROR = { STATE: "error" };
  static readonly PLAID_AUTH = { STATE: "plaid-oauth", PATH: "plaid-oauth" };
  static readonly SSO_REDIRECT = { STATE: "redirect", PATH: "banno-sso-redirect" };
  static readonly CREATE_NEW_APPLICANT = { STATE: "CREATE_NEW_APPLICANT" };
  static readonly CREATE_NEW_BUSINESS_APPLICANT = { STATE: "CREATE_NEW_BUSINESS_APPLICANT" };
  static readonly SET_BUSINESS_ACTIVE = { STATE: "SET_BUSINESS_ACTIVE" };
  static readonly SET_INVITE_PERSON_COMPLETE = { STATE: "SET_INVITE_PERSON_COMPLETE" };
  static readonly SET_PRIMARY = { STATE: "SET_PRIMARY" };
  static readonly APPLICANT = { STATE: "APPLICANT" };
  static readonly WELCOME = { STATE: "WELCOME" };
  static readonly ADDRESS_CONFIRMATION = { STATE: "ADDRESS_CONFIRMATION" };
  static readonly INTERIM = { STATE: "Interim" };
  static readonly ENROLLMENT = { STATE: "enrollment" };
  static readonly DECISION = { STATE: "DECISION" };
  static readonly ADDRESS = { STATE: "ADDRESS" };
  static readonly BUSINESS_INFO = { STATE: "BUSINESS_INFO" };
  static readonly BUSINESS_OWNERSHIP = { STATE: "BUSINESS_OWNERSHIP" };
  static readonly BUSINESS_RESOLUTION = { STATE: "BUSINESS_RESOLUTION" };
  static readonly CAPTURE = { STATE: "CAPTURE" };
  static readonly APPLICANT_CIP = { STATE: "APPLICANT_CIP" };
  static readonly DEMOGRAPHICS = { STATE: "DEMOGRAPHICS" };
  static readonly DOCUMENTS = { STATE: "DOCUMENT" };
  static readonly EMAIL = { STATE: "EMAIL" };
  static readonly EMPLOYMENT = { STATE: "EMPLOYMENT" };
  static readonly EMPLOYMENT_HISTORY = { STATE: "EMPLOYMENT_HISTORY" };
  static readonly EXISTING = { STATE: "EXISTING" };
  static readonly EXPENSES = { STATE: "EXPENSES" };
  static readonly IDENTIFICATION = { STATE: "IDENTIFICATION" };
  static readonly INCOME = { STATE: "INCOME" };
  static readonly INITIAL_DISCLOSURE = { STATE: "INITIAL_DISCLOSURE" };
  static readonly INVITE_APPLICANTS_STATUS = { STATE: "INVITE_APPLICANTS_STATUS" };
  static readonly LOAN_OFFER = { STATE: "LOAN_OFFER" };
  static readonly LOAN_FUNDING = { STATE: "LOAN_FUNDING" };
  static readonly LOAN_SUMMARY = { STATE: "LOAN_SUMMARY" };
  static readonly PERSON_INFO = { STATE: "PERSON_INFO" };
  static readonly PHONE = { STATE: "PHONE" };
  static readonly PREQUALIFICATION = { STATE: "PREQUALIFICATION" };
  static readonly SIGNATURE = { STATE: "SIGNATURE" };
  static readonly SSO = { STATE: "SSO" };
  static readonly SSO_V0 = { STATE: "SSO_V0" }
  static readonly WALL = { STATE: "WALL" };
  static readonly PROMO_CODE = { STATE: "PROMO_CODE" };
  static readonly ADDITIONAL_INCOME = { STATE: "ADDITIONAL_INCOME" };
  static readonly REFERENCES = { STATE: "REFERENCES" };

  /**ENROLLMENT */
  static readonly BENEFICIARY_INFO = { STATE: "BENEFICIARY_INFO" };
  static readonly MAILING = { STATE: "MAILING" };
  static readonly AUTO_ADD_SERVICES = { STATE: "AUTO_ADD_SERVICES" };
  static readonly BANNO_ENROLL = { STATE: "BANNO_ENROLL" };
  static readonly CONFIGURE_SHARE_ACCOUNTS = { STATE: "CONFIGURE_SHARE_ACCOUNTS" };
  static readonly LINK_ENROLLMENT_APPLICANTS = { STATE: "LINK_ENROLLMENT_APPLICANTS" };
  static readonly LINK_ONE_ENROLLMENT_APPLICANT = { STATE: "LINK_ONE_ENROLLMENT_APPLICANT" };
  static readonly SEND_DISCLOSURE_EMAILS = { STATE: "SEND_DISCLOSURE_EMAILS" };
  static readonly SET_OWNERSHIP = { STATE: "SET_OWNERSHIP" };
  static readonly SET_SUGGESTED_ROLES = { STATE: "SET_SUGGESTED_ROLES" };
  static readonly SET_SUGGESTED_TITLES = { STATE: "SET_SUGGESTED_TITLES" };
  static readonly ENROLLMENT_ACTION = { STATE: "enrollment-action" };
  static readonly ACCOUNT_APPLICANT_DISCLOSURE = { STATE: "ACCOUNT_APPLICANT_DISCLOSURE" };
  static readonly BRANCH_SELECTION = { STATE: "BRANCH_SELECTION" };
  static readonly CONGRATULATIONS = { STATE: "CONGRATULATIONS" };
  static readonly CHOOSE_PRIMARY = { STATE: "CHOOSE_PRIMARY" };
  static readonly ENROLLMENT_CIP = { STATE: "CIP" };
  static readonly ENROLLMENT_ERROR = { STATE: "ERROR" };
  static readonly FINAL_DISCLOSURE = { STATE: "FINAL_DISCLOSURE" };
  static readonly FUNDING = { STATE: "FUNDING" };
  static readonly PERSON_RELATIONSHIP = { STATE: "PERSON_RELATIONSHIP" };
  static readonly PRODUCT_GUIDE = { STATE: "PRODUCT_GUIDE" };
  static readonly PRODUCT_SELECTION = { STATE: "PRODUCT_SELECTION" };
  static readonly RIGHT_OF_SURVIVORSHIP = { STATE: "RIGHT_OF_SURVIVORSHIP" };
  static readonly RULE_DECISION = { STATE: "RULE_DECISION" };
  static readonly SAVE = { STATE: "SAVE" };
  static readonly SERVICES = { STATE: "SERVICES" };
  static readonly SHOW_DISCLOSURE = { STATE: "SHOW_DISCLOSURE" };
  static readonly SUMMARY = { STATE: "SUMMARY" };
  static readonly VALIDATE_PLAID = { STATE: "VALIDATE_PLAID" };
  static readonly WOLTERS_KLUWER = { STATE: "WOLTERS_KLUWER" };
  static readonly ERROR_NOA = { STATE: "ERROR_NOA" };
  static readonly ERROR_LOAN_NOA = { STATE: "ERROR_LOAN_NOA" };
  // additional added routes
  static readonly NOT_FOUND = { STATE: "NOT_FOUND" };
  static readonly DESKTOP = { STATE: "desktop" };
}
