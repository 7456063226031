import { clone, isDate, isString, isNumber, isUndefined } from "lodash";
import LookupFactory from "@/common/services/Lookup/LookupFactory";
import ConversionFactory from "@/common/services/utils/conversionFactory";
import ApplicantBusinessFactory from "@/common/services/Applicant/ApplicantBusinessFactory";
import ApplicantPersonFactory from "@/common/services/Applicant/ApplicantPersonFactory";
import LogService from "@/common/services/Log/LogService";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { injectable, inject } from "inversify";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import FACTORY_MSG from "@/common/messages/factory.messages";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import type IApplicant from "@/common/models/IApplicant";
import type { Validation } from "@/common/models/IStateValidationResponse";

@injectable()
class ApplicantFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LogService) private logService: LogService,
    @inject(LookupFactory) private lookupFactory: LookupFactory,
    @inject(ConversionFactory) private conversionFactory: ConversionFactory,
    @inject(ApplicantBusinessFactory) private applicantBusinessFactory: ApplicantBusinessFactory,
    @inject(ApplicantPersonFactory) private applicantPersonFactory: ApplicantPersonFactory
  ) {}

  async getAll(workspaceUUID: string, queryParams: any): Promise<Array<IApplicant>> {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant`,
      queryParams,
      ResponseTypes.Payload
    );
    return response;
  }

  challenge(workspaceUUID: string, applicant: { applicantId: number }) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicant.applicantId}`,
      applicant,
      { params: { challenge: true } },
      ResponseTypes.Payload
    );
  }

  requestSso(workspaceUUID: string, applicantId: number, applicant: { applicantId: number }) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicant.applicantId}`,
      applicant,
      { params: { auth: true } },
      ResponseTypes.Payload
    );
  }

  respondSso(workspaceUUID: string, applicantId: number, applicant: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}`,
      applicant,
      { params: { token: true } },
      ResponseTypes.Payload
    );
  }

  async get(workspaceUUID: string, applicantId: number) {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}`,
      {},
      ResponseTypes.Payload
    );
    return response[0];
  }

  async create(workspaceUUID: string, data: any) {
    const response = await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant`,
      data,
      {},
      ResponseTypes.Payload
    );
    return response[0];
  }

  delete(workspaceUUID: string, applicantId: number) {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}`,
      {},
      ResponseTypes.Data
    );
  }

  post(workspaceUUID: string, applicantType: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant`,
      { applicantState: "CREATED", applicantType: applicantType },
      {},
      ResponseTypes.Payload
    );
  }

  async updateApplicantState(workspaceUUID: string, applicantId: number, state: any) {
    const response = await this.lookupFactory.ApplicantState?.getAll();
    return response.some((applicantState: any) => {
      return applicantState.typeCode === state;
    })
      ? this.httpHandler.put(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/state`,
          {
            applicantId: applicantId,
            applicantState: state
          },
          {},
          ResponseTypes.Data
        )
      : Promise.resolve();
  }

  getApplicantValidation(workspaceUUID: string, applicantId: number): Promise<Array<Validation>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/validate?type=ALL`,
      {},
      ResponseTypes.Payload
    );
  }

  getApplicants(workspaceUUID: string, applicantType?: any): Promise<IApplicant[]> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant`,
      { params: applicantType ? { applicantType: applicantType } : {} },
      ResponseTypes.Payload
    );
  }

  convertApplicantPerson(person: any) {
    if (isUndefined(person) || person === null) {
      return Promise.resolve(person);
    }
    if (isString(person.dateOfBirth)) {
      person.dateOfBirth = this.conversionFactory.stringToDate(person.dateOfBirth);
    }
    return Promise.resolve(person);
  }

  revertApplicantPerson(person: any) {
    person.dateOfBirth = this.conversionFactory.dateToString(person.dateOfBirth);
    return person;
  }

  async getApplicant(workspaceUUID: string, applicantId: number): Promise<IApplicant> {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}`,
      {},
      ResponseTypes.Payload
    );
    const person = await this.convertApplicantPerson(response[0].person);
    response[0].person = person;
    return response[0];
  }

  async putApplicant(workspaceUUID: string, applicantId: number, message: any) {
    if (!isUndefined(message.person) && isDate(message.person.dateOfBirth)) {
      message.person.dateOfBirth = this.conversionFactory.dateToString(message.person.dateOfBirth);
    }

    const response = await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}`,
      message,
      {},
      ResponseTypes.Payload
    );
    if (response[0].person && response[0].person.dateOfBirth) {
      response[0].person.dateOfBirth = this.conversionFactory.stringToDate(response[0].person.dateOfBirth);
    }
    return response[0];
  }

  getApplicantListPerson(uuid: string, applicantList: any[]) {
    return Promise.all(
      applicantList.map((applicant) => {
        return this.getApplicantPerson(uuid, applicant.applicantId).then(
          (response) => {
            applicant.person = response;
            return applicant;
          },
          () => {
            this.logService.error(FACTORY_MSG.APPLICANT.FAILED_SET_PERSON);
            return applicant;
          }
        );
      })
    );
  }

  async getApplicantPerson(workspaceUUID: string, applicantId: number) {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/person`,
      {},
      ResponseTypes.Payload
    );
    return response === null ? null : this.convertApplicantPerson(response);
  }

  async putApplicantPerson(uuid: string, applicantId: number, applicantPerson: any) {
    let message = clone(applicantPerson);
    message = this.revertApplicantPerson(message);
    const response = await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/person`,
      message,
      {},
      ResponseTypes.Payload
    );
    return this.convertApplicantPerson(response);
  }

  async postApplicantPerson(uuid: string, applicantId: number, applicantPerson: any) {
    let message = clone(applicantPerson);
    message = this.revertApplicantPerson(message);
    const response = await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/person`,
      message,
      {},
      ResponseTypes.Payload
    );
    return this.convertApplicantPerson(response);
  }

  isExisting(applicant: any) {
    return isNumber(applicant.coreId) || isString(applicant.coreId);
  }

  async setApplicantInfo(uuid: string, applicant: IApplicant): Promise<IApplicant> {
    return applicant.applicantType === WORKFLOW_TYPES.BUSINESS
      ? await this.applicantBusinessFactory.set(uuid, applicant)
      : await this.applicantPersonFactory.set(uuid, applicant);
  }

  setApplicantListInfo(uuid: string, applicantList: any) {
    return Promise.all(
      applicantList.map((applicant: any) => {
        return this.setApplicantInfo(uuid, applicant);
      })
    );
  }
}
export default ApplicantFactory;
