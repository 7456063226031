import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import PollingService from "@/common/services/utils/PollingService";
import { DisplayConstant } from "@/common/constant/DisplayConstant";
import ENROLLMENT_DISCLOSURE_TYPES from "@/common/enums/enrollmentDisclosureTypesEnum";
import { workspaceStatusConstant } from "@/branchmanager/app/constant/workspaceStatusConstant";

@injectable()
class EnrollmentAccountDisclosureFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(PollingService) private pollingService: PollingService
  ) {}

  private async getWorkspaceStatus(
    workspaceUUID: string
  ) {
    return (await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/status`,
      {},
      ResponseTypes.Payload
    ))?.statusCode;
  }

  private async getAllAccountDisclosuresSync(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number
  ) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/disclosure`,
      {},
      ResponseTypes.Payload
    );
  }

  private async postAllAccountDisclosures(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number
  ): Promise<any> {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/disclosure`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  private async getAllAccountDisclosures(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number,
    requestId: string
  ) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/disclosure-async/${requestId}`,
      {},
      ResponseTypes.Payload
    );
  }

  private async getAllAccountDisclosureStatus(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number,
    requestId: string
  ) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/disclosure-status/${requestId}`,
      {},
      ResponseTypes.Payload
    );
  }

  private async isCreated(workspaceUUID: string, enrollmentId: number, accountId: number, requestId: string) {
    return this.getAllAccountDisclosureStatus(workspaceUUID, enrollmentId, accountId, requestId).then(
      (res: any) => res === DisplayConstant.FOUND
    );
  }

  private async awaitAllAccountDisclosuresAvailable(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number,
    requestId: string
  ) {
    return await this.pollingService
      .awaitPredicate(1000, 1000 * 60, () => this.isCreated(workspaceUUID, enrollmentId, accountId, requestId))
      .then(() => ({ requestId }));
  }

  private async makeAccountDisclosurePackage(accountId: number, accountDisclosures: any) {
    return {
      type: ENROLLMENT_DISCLOSURE_TYPES.ENROLLMENT_ACCOUNT,
      id: accountId,
      disclosures: accountDisclosures || []
    };
  }

  async getDisclosurePackage(workspaceUUID: string, enrollmentId: number, accountId: number) {
    if (await this.getWorkspaceStatus(workspaceUUID) === workspaceStatusConstant.CLOSED)
    {
      // If the workspace is closed, the call below to `this.postAllAccountDisclosures()`
      // will fail, because we don't allow POST calls to closed workspaces.  However,
      // if the workspace is closed the disclosures should have already been created,
      // so we can just retrieve them using a GET instead.
      return await this.getAllAccountDisclosuresSync(workspaceUUID, enrollmentId, accountId)
        .then(async (res) => await this.makeAccountDisclosurePackage(accountId, res));
    }

    return await this.postAllAccountDisclosures(workspaceUUID, enrollmentId, accountId)
      .then(async (res: any) => await this.awaitAllAccountDisclosuresAvailable(workspaceUUID, enrollmentId, accountId, res.requestId))
      .then(async (res: any) => await this.getAllAccountDisclosures(workspaceUUID, enrollmentId, accountId, res.requestId))
      .then(async (res) => await this.makeAccountDisclosurePackage(accountId, res));
  }
}
export default EnrollmentAccountDisclosureFactory;
